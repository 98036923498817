<template>
  <div>
    <p style="height: 10px;background-color: #F6F6F6;"></p>
    <div style="margin-left: 16px;margin-right: 15px;text-align: center;">
      <p class="amount-title">交易金额（元）</p>
      <p class="amount-text">
        {{amount}}
      </p>

      <van-row type="flex" justify="space-between" style="margin-top: 60px;margin-bottom: 15px;">
        <van-col span="12" class="info-title-left">交易状态</van-col>
        <van-col span="12" class="info-content-right">{{status}}</van-col>
        <van-col span="12" class="info-title-left">实收金额</van-col>
        <van-col span="12" class="info-content-right">{{real_amount}}</van-col>
      </van-row>

    </div>
    <p style="height: 10px;background-color: #F6F6F6;margin-bottom: 0px;"></p>

    <div style="margin-left: 16px;margin-right: 15px;text-align: center;">
      <van-row type="flex" justify="space-between" style="margin-top: 0px;">
        <van-col span="8" class="info-title-left">收款时间</van-col>
        <van-col span="16" class="info-content-right">{{receive_time}}</van-col>
        <van-col span="8" class="info-title-left">付款方式</van-col>
        <van-col span="16" class="info-content-right">{{pay_method}}</van-col>
        <van-col span="8" class="info-title-left">收款流水号</van-col>
        <van-col span="16" class="info-content-right">{{order_no}}</van-col>
        <van-col span="8" class="info-title-left">手续费</van-col>
        <van-col span="16" class="info-content-right">{{fee}}</van-col>
        <van-col span="8" class="info-title-left">抽成</van-col>
        <van-col span="16" class="info-content-right">{{commission}}</van-col>
        <van-col span="8" class="info-title-left">结算金额</van-col>
        <van-col span="16" class="info-content-right">{{final_amount}}</van-col>
      </van-row>
    </div>
  </div>
</template>

<script>
export default {
  name: "tradedetail",
  data() {
    return {
      amount: "",
      status: "",
      real_amount: "",
      receive_time: "",
      pay_method: "",
      fee: "",//手续费
      commission: "",//佣金
      final_amount: "",
      order_no:"",
    }
  },
  methods: {
    get_detail() {
      // this.$route.query.commissionFee
      this.amount       = (this.$route.query.txnAmtPay / 100.0).toFixed(2).toString()
      this.status       = (this.$route.query.status == "SUCCESS")?"交易成功":"交易失败"
      this.real_amount  = (this.$route.query.txnAmtPay / 100.0).toFixed(2).toString()
      this.receive_time = this.$route.query.date
      this.order_no     = this.$route.query.orderNo
      this.pay_method   = this.$route.query.payTypeDesc
      this.fee          = (this.$route.query.rateFee / 100.0).toFixed(2).toString()//手续费
      this.commission   = (this.$route.query.commissionFee / 100.0).toFixed(2).toString()//佣金
      this.final_amount = (this.$route.query.entryAmt / 100.0).toFixed(2).toString() // 结算金额
    },
  },
  mounted() {
    this.get_detail()
  },
  created() {
  },
}
</script>

<style scoped>
.amount-title {
  width: calc(100vw - 30px);
  height: 15px;
  font-size: 15px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #333333;
  line-height: 15px;
  opacity: 0.7;
  margin-top: 42px;
}

.amount-text {
  width: calc(100vw - 40px);
  height: 34px;
  font-size: 45px;
  letter-spacing: 2px;
  font-family: PingFang SC;
  font-weight: 600;
  color: #333333;
  line-height: 45px;
  margin-top: 24px;
}

.info-title-left {
  text-align: left;
  color: #707070;
  margin-top: 18px;
}

.info-content-right {
  text-align: right;
  margin-top: 18px;
}
</style>